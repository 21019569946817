.Grid-Height-Tall {
    height: 80vh;
  }
  .Grid-Header-Tall {
    padding: 0.5em;
    height: 7vh;
  }
  .MuiGrid-grid-xs-12 {
    flex-basis: inherit;
    width: 100%;
  }
  .Big-Cell-Font {
    font-size: 20px;
    font-weight: bold;
  }
  


  .tooltip-header {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 5px;
    border-bottom: 1px solid #c5c5c5;
  }
  
  .tooltip-body {
    width: 170px;
  }
  
  .tooltip-body .series-name {
    font-weight: normal;
    opacity: 0.6;
    display: inline-block;
    line-height: 1.5;
    padding-right: 10px;
    width: 126px;
  }
  
  .tooltip-body .value-text {
    display: inline-block;
    line-height: 1.5;
    width: 30px;
  }