
.Paper {
    padding: 1.5em;
}

.paper-small {
    padding: 15px;
    display: block;
}

.Paper-Hover:hover {
    background-color: #f4f4f4;
    cursor: pointer;
}

.Info {
    text-align: center;
    border: 3px solid; 
    border-radius: 10px;
    padding: 10px;
}

.Info-Container {
    text-align: center;
    padding: 20px;
}

.License-List {
    max-height: 500px;
    min-height: 300px;
    overflow-y: scroll;
}

.page-size {
    width:  380mm;
    position: relative;
    margin-top: 1rem;
    display: block;
    height: 100%;
}

.page-size2 {
    width:  100%;
    position: relative;
    margin-top: 1rem;
    display: block;
    height: 100%;
}

@media print {
    .print-page-size {
        width:  380mm;
    }
    * {
        -webkit-print-color-adjust: exact;
      }
    html, body {
        zoom: 75%;
        height: 100%; /* Use 100% here to support printing more than a single page*/
        margin: 0 !important;
        padding: 0 !important;
    }
    .page-break {
        page-break-inside: avoid;
        page-break-before: always;
    }
    .no-page-break {
        page-break-before: avoid;
    }
    .page-break-after {
        break-after: always;
        break-before: avoid;
        break-inside: avoid-page;
        margin-top: 1rem;
        display: block;
        position: relative;
    }
    .page-root {
    }
    .whole-page {
        height: 100vh;
    }
    .qr-code-field {
        text-align: center;
        border-radius: 20px;
        padding: 20px;
    }
}

.Flex-Row-Center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    
}

@media print {
    .group-print-group-value { height: 9vh; }
}

@media print {
    .group-print-group-value { height: 9vh; }
}

@media print {
    .group-print-header {  }
}

@media print {
    .group-print-content-cockpit-services { height: 83vh;  margin-bottom: 1vh;}
}

@media print {
    .group-print-content-cockpit-services-time { height: 84vh; }
}

@media print {
    .group-print-content-cockpit-space { height: 2vh; }
}

@media print {
    .group-print-content-cockpit-content { height: 100vh; }
}
@media print {
    .group-print-content-cockpit-bar { height: 60vh; }
}