p.Paper-Result {
    font-size: x-large;
    font-weight: bolder;
}
.Paper-Panel-Icon path {
    fill: rgb(255, 255, 255);
 }

 .Result {
    font-size: large;
    font-weight: 900;
 }

 .white-font {
     color: white;
 }
 .font-subheader-drawer {
    color: black;
}
 .right-typo {
     text-align: right;
 }

 .bold-typo {
     font-weight: bold;
 }

 .header-dialog-default-title {
     font-size: larger;
     font-weight: bolder;
 }


 .avg-font
 {
    text-align:center; 
    margin-bottom: 10px;
    font-size: 40px;
 }